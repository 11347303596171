import React from "react"
import "./test.css"
import { withRouter } from "react-router-dom";
const Test = () => {
    return (
        <div className="font-main">
            <div className="text-row big-text big-black">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Black</p>
                <p>20px</p>
            </div>
            <div className="text-row big-text big-heavy">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Heavy</p>
                <p>20px</p>
            </div>
            <div className="text-row big-text big-bold">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Bold</p>
                <p>20px</p>
            </div>
            <div className="text-row big-text big-semibold">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>SemiBold</p>
                <p>20px</p>
            </div>
            <div className="text-row big-text big-medium">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Medium</p>
                <p>20px</p>
            </div>
            <div className="text-row big-text big-regular">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Regular</p>
                <p>20px</p>
            </div>
            <div className="text-row big-text big-light">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Light</p>
                <p>20px</p>
            </div>
            <div className="text-row big-text big-thin">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Thin</p>
                <p>20px</p>
            </div>
            <div className="text-row big-text big-ultralight">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>UltraLight</p>
                <p>20px</p>
            </div>

            <div className="text-row md-text big-black" style={{ marinTop: "17px" }}>
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Black</p>
                <p>8px</p>
            </div>
            <div className="text-row md-text big-heavy">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Heavy</p>
                <p>8px</p>
            </div>
            <div className="text-row md-text big-bold">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Bold</p>
                <p>8px</p>
            </div>
            <div className="text-row md-text big-semibold">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>SemiBold</p>
                <p>8px</p>
            </div>
            <div className="text-row md-text big-medium">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Medium</p>
                <p>8px</p>
            </div>
            <div className="text-row md-text big-regular">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Regular</p>
                <p>8px</p>
            </div>
            <div className="text-row md-text big-light">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Light</p>
                <p>8px</p>
            </div>
            <div className="text-row md-text big-thin">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Thin</p>
                <p>8px</p>
            </div>
            <div className="text-row md-text big-ultralight">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>UltraLight</p>
                <p>8px</p>
            </div>
            <div className="text-row sm-text big-black" style={{ marinTop: "17px" }}>
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Black</p>
                <p>6px</p>
            </div>
            <div className="text-row sm-text big-heavy">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Heavy</p>
                <p>6px</p>
            </div>
            <div className="text-row sm-text big-bold">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Bold</p>
                <p>6px</p>
            </div>
            <div className="text-row sm-text big-semibold">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>SemiBold</p>
                <p>6px</p>
            </div>
            <div className="text-row sm-text big-medium">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Medium</p>
                <p>6px</p>
            </div>
            <div className="text-row sm-text big-regular">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Regular</p>
                <p>6px</p>
            </div>
            <div className="text-row sm-text big-light">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Light</p>
                <p>6px</p>
            </div>
            <div className="text-row sm-text big-thin">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>Thin</p>
                <p>6px</p>
            </div>
            <div className="text-row sm-text big-ultralight">
                <p>Testing the line on several devices, to ensure no difference. Different weights different sizes.</p>
                <p>UltraLight</p>
                <p>6px</p>
            </div>
        </div>
    )
}
export default withRouter(Test)