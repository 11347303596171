import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { FILE_SERVER, forwardMessage } from '../../../constants';
import Alert from '../WhatsApp/components/Sidebar/Alert';
import Contact from '../WhatsApp/components/Sidebar/Contact';
function ForwardListChat(props) {
  const dispatch = useDispatch()
  let forwarderMessage = useSelector(state => state.whatsapp?.forwarderMessage) || null;

  const setId = (ml) => {
    let id = ml;

    dispatch({ type: "CHAT_ID", payload: ml });
  };
  function handleSetUser(contact, id) {
    setId(null);
    dispatch({ type: "OPEN-CHAT", payload: null })


    dispatch({ type: "OPEN-CHAT", payload: contact })
    forwardMessage(forwarderMessage, contact)
    dispatch({ type: "OPEN-CHAT", payload: contact })
  }
  const openAdmin = () => {

    ;
  }
  const makeContact = (contact) => {
    let obj = { name: null, id: null, messages: { data: [] }, isGroup: null, profile_photo: null }
    obj.name = (contact?.channel_type?.slug === "team" ? contact.channel_translations[0].name :
      contact?.channel_members?.filter((a) =>
        parseInt(a?.user_id) !==
        parseInt(JSON.parse(localStorage.getItem("user")).id)
      )[0]?.user.full_name?.length > 0 ? contact?.channel_members?.filter((a) =>
        parseInt(a?.user_id) !==
        parseInt(JSON.parse(localStorage.getItem("user")).id)
      )[0]?.user.full_name : contact?.channel_members?.filter((a) =>
        parseInt(a?.user_id) !==
        parseInt(JSON.parse(localStorage.getItem("user")).id)
      )[0]?.user.username)
    obj.id = contact.id

    obj.isGroup = contact?.channel_type?.slug === "team" ? 1 : 0;
    obj.profile_photo = (contact?.channel_type?.slug === "team" ? contact.photo_path ? FILE_SERVER + contact.photo_path : null :
      contact?.channel_members?.filter((a) =>
        parseInt(a?.user_id) !==
        parseInt(JSON.parse(localStorage.getItem("user")).id)
      )[0]?.user.avatar?.length > 0 ? FILE_SERVER + contact?.channel_members?.filter((a) =>
        parseInt(a?.user_id) !==
        parseInt(JSON.parse(localStorage.getItem("user")).id)
      )[0]?.user.avatar : null)
    return obj
  }
  return (
    <div className="sidebar__contacts">
      {props.chats.length > 0 && <Alert noAdmin={true} noGroup={false} chat={props.chatss.filter((s) => s.channel_type.slug !== "team")[0]} name={props.chatss.filter((s) => s.channel_type.slug !== "team")[0]?.channel_translations[0]?.name ? props.chatss.filter((s) => s.channel_type.slug !== "team")[0].channel_translations[0]?.name : "Group"} new={0} onClick={() => openAdmin()} openChat={() => handleSetUser(props.chatss.filter((s) => s.channel_type.slug === "team")[0])} />}
      {props.chats.map((contact, index) => {
        return (
          <Contact
            onClick={() => { handleSetUser(contact, contact?.id) }}
            key={index}
            contact={makeContact(contact)}
          />
        )
      })}
    </div>
  )
}

export default ForwardListChat