
import React, { useEffect, useRef, useState } from "react";
import { useStopwatch } from "react-timer-hook";
import { toast } from "react-toastify";
import Icon from "./Icon";
import MicRecorder from 'mic-recorder-to-mp3';
function Recorder(props) {
  let Mp3Recorder =useRef()
  const startRecord = () => {
    Mp3Recorder.current = new MicRecorder({  bitRate: 64 ,
      prefix: "data:audio/wav;base64,", });
      Mp3Recorder.current
        .start()
        .then(() => {
         
          start();
        }).catch((e) => console.error(e));
  };
  const stop = () => {

    Mp3Recorder.current
      ?.stop()
      ?.getMp3()
      .then(([buffer, blob]) => {
      
        const blobURL = URL.createObjectURL(blob)
        
        props.send(blob)
        reset()
        send.current=true
        streamVar.current?.getTracks()?.forEach((track) => {
          track.stop();
        });
      }).catch((e) => console.log(e));
  };
const cancelRecord=()=>{
  props.setRec(false);
  Mp3Recorder.current
  ?.stop()
  .then(([buffer, blob]) => {
    props.setRec(false);
    // blobs.current=null;
    send.current=false
    reset();
    streamVar.current?.getTracks()?.forEach((track) => {
      track.stop();
    });
  }).catch((e) => console.log(e));

}
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });
  const send = useRef(null);
  let streamVar=useRef()
  useEffect(() => {
    
    props.rec&&
      navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(
        (stream) => {
          reset()
          streamVar.current=stream
          startRecord()
               },
        (e) => {
          toast.error("No available Microphone");
          console.error(e);
        }
      );
     
  }, [props.rec]);
  return (
    <>
      {<div className="recorder-cont">
        <div className="recorder-row1">
          <div className="recorder-duration first-wa">{minutes>9?minutes:"0"+minutes}:{seconds>9?seconds:"0"+seconds}</div>
          <div className="recorder-status" style={{opacity:(seconds%2===0)?"1":"0"}}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 20 20"><g><circle id="Ellipse_355-2" data-name="Ellipse 355" cx="3.5" cy="3.5" r="3.5" fill="#ce1a02" transform="translate(7.5, 7.5)"></circle></g></svg>
             <div className="recorder-tex" >Recording...</div>
          </div>
         
        </div>
        <div className="recorder-row1">
            <div className="del-icon-wa first-wa" onClick={()=>{cancelRecord()}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48"><path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3" d="M19.5,11.5V10c0-2.5,2-4.5,4.5-4.5s4.5,2,4.5,4.5v1.5"></path><line x1="8.5" x2="39.5" y1="11.5" y2="11.5" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line><line x1="36.5" x2="36.5" y1="23.5" y2="11.5" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" 
></line><path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" d="M11.5,18.7v19.8c0,2.2,1.8,4,4,4h17c2.2,0,4-1.8,4-4V31" ></path><line x1="20.5" x2="20.5" y1="19.5" y2="34.5" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line></svg>
 </div>
            <div className="del-icon-wa"></div>
            <div className="del-icon-wa last-wa">
              <div className="send_button_img" onClick={()=>{
               send.current=true
             stop()
               
             
              }}>
                <Icon id="send"/>
              </div>
            </div>
          </div>
      </div>}
      </>
  );
}
export default Recorder;
