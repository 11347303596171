import React from 'react'
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../Spinner';

const SearchComponent = (props) => {
  const loading=useSelector((state)=>state.whatsapp.search_progress)
  return (
    <div className="search-component">
     {(loading&&!props.conversation)?<Spinner/>: <svg data-src={props.svg}></svg>}
      <input onChange={(e) => { props.onChange(e); }} placeholder={props.placeholder} />
    </div>
  )
}
export default SearchComponent