import axios from "axios";
import {
  all,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ONLINE,
  OFFLINE
} from 'redux-offline-status/lib/actions'
import store from "../store";




function* getGroupInfo(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    // yield put({ type: "WA_LOADING" });
    let res = yield AxiosInstace.get(
      "/api/v1/whatsapp_groups/show/" +
      action.id
    );

    yield put({
      type: "GI_RED",
      payload: { id: action.id, data: res.data.data.group_meta_data_json },
    });
    // yield put({ type: "WA_DONE" });
  } catch (e) {
    // yield put({ type: "WA_DONE" });
  }
}
function* watchGetGroupInfo() {
  yield takeEvery("GET_GI", getGroupInfo);
}
function* GetChat(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    if (
      (store.getState().whatsapp.sending ||
        store.getState().whatsapp.wa_loading)

    ) {

    }
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    let res;
    if (!store.getState().whatsapp.wa_loading) {
      // yield put({ type: "WA_LOADING" });
      res = yield AxiosInstace.get(
        `/api/v1/whatsapp/get_chats_with_latest_messages?limit=20`,
      );
      if (false) {
        res.data.data.map((a) => {
          if (a.isGroup === 1) store.dispatch({ type: "GET_GI", id: a.id });
        });
      }

      // yield put({ type: "WA_DONE" });
      if (!store.getState().whatsapp.wa_loading)
        if (action.isFirst)
          yield put({ type: "GET_CHAT_WA_RED", payload: res.data.data });
        else {
          if (!store.getState().whatsapp.sending)
            yield put({ type: "UPD_CHAT_WA_RED", payload: res.data.data, scr: true, not: action.not });
        }
    }
  } catch (e) {
    store.dispatch({ type: OFFLINE });

  }
}
function* watchGetChat() {
  yield takeEvery("GET_WA_CHATS", GetChat);
}
function* sendText(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let res;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let AxiosFileInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    });


    // yield put({ type: "WA_LOADING" });
    if (!action.isGroup) {
      res = yield AxiosInstace.post(
        "/api/v1/whatsapp/send_message_to_user",
        JSON.stringify(action.pay)
      );
      yield put({
        type: "UPLOAD_SUC",
        mid: action.mid,
        cid: action.cid,
        id: res.data.data.id,
      });
    }
    else {
      res = yield AxiosFileInstace.post(
        "/api/v1/whatsapp/send_message_to_group",
        action?.pay
      );
      yield put({
        type: "UPLOAD_SUC",
        mid: action.mid,
        cid: action.cid,
        id: res.data.data.id,
      });
    }
    yield put({ type: "SENDING_END" });
    // setTimeout(() => {
    //   put({ type: "WA_DONE" });
    //   put({ type: "SENDING_END" });
    // }, 3000);
  } catch (e) {
    console.error(e);
    put({ type: "SENDING_END" });
  }
}
function* watchSendText() {
  yield takeEvery("SEND_TEXT_SAGA", sendText);
}
function* getGroubs(action) {
  try {
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization:
          "Bearer " +
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYTA2NzEyNTE1MGM4NDYyZDFhMjAzZTYzNTllYzY4NzYyYzg2ZDcyMTU4MDkxOGNkZWVkMDE0MDU1MDY0NDI3NjllYzc5MGY0MmEwNTczZTkiLCJpYXQiOjE2NjMzMjQ1OTIuNTU3MjIyLCJuYmYiOjE2NjMzMjQ1OTIuNTU3MjI0LCJleHAiOjE2NzExMDA1OTIuNTUxNTQ5LCJzdWIiOiI0NCIsInNjb3BlcyI6WyIxNSIsIjciLCI1IiwiMjIiLCI2Il19.YA9xil5L_RG_sT_HSqJIT5rdWRBHTNJegoDDBl1yceHFW3HgZMfiFoaUDo0XEblMHQlTnCCcd9zrj0SAYFFHLNmTVemozNP3wCrBSLY6rWTfv4GeQI1r-jukZE1VmjlQDGtm764-5D1NmKF_u71bGfkzJMGmkBQZqOFUIyVX72ByFYtiP47lCzoiryMJZxoRlzr4HkLcClFBjCFM0yOn9khLtvGSxEFpemQvg7Zs5K5EmFuYv2b2oFVugk7BpTTvSxzupoU3QNoXtXEP-SR28eETFrGQOUfwbTdDg_0Srj6fu6_rZHkN2Ofe98QlSf-SwkgSK5rq1pVD0bCO5k7rsfrFGpgkULsXkUHgA0EF3so77um3YrwGqtKouR1d1qrrPNvScJMHwiAf5uQ5qnRgeMDYScSFoKCELtWO2C7PjjAce2BFENOXK4EIDMzDzg5H69h5cOXSX5Ydp4j_ETIejJkHuh0qK8w9volvn7ml9xPg0Rr7Zqaduv7nyGsDcZXNI0UdyUl0KL0uPaJ8kgN6-crzKf75QIen1GGJBl8OqEfzg-y8z3vfDuy620tPAGwxGkJFH-n-ELJt59pwg620RI5raxT7EDKgjBSxbgV6wE5j_qAgyqmw93tRqc-lo4ikfFzZ6z2u83LLdxrML8l21mqUe-GO1B9kCcTo_h-qHq0",
        "Content-Type": "application/json",
      },
    });
    // yield put({ type: "WA_LOADING" });

    // yield put({ type: "WA_DONE" });
  } catch (e) { }
}
function* watchGetGroubs() {
  yield takeEvery("GET_WA_GROUB", getGroubs);
}
function* getPage(action) {
  try {
    let id = action.id
    let page = action.page;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    //EDIT
    let resp = yield AxiosInstace.get(
      `/api/v1/whatsapp/get_messages_of_chat?limit=500&last_whatsapp_chat_message_id=${page}&whatsapp_chat_id=${id}`
    );
    yield put({
      type: "GET_PAGE", payload: {
        messages: resp.data.data,
        id: action.id
      }
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: "GET_PAGE_CHAT_RED", payload: {
        messages: [],
        id: action.id
      }
    });
  }
}
function* watchGetPage() {
  yield takeEvery("GET_PAGE_CHAT", getPage);
}
// function* sendFile(action) {
//   try {
//     const token = store.getState()?.whatsapp?.userTokenWhatsapp;

//     const base_user =
//       store?.getState().whatsapp.fetched_url === true
//         ? store?.getState()?.whatsapp.base_user
//         : process.env.REACT_APP_USER_BASE_URL;
//     let AxiosInstace = axios.create({
//       baseURL: `${base_user}`,
//       headers: {
//         Authorization: "Bearer " + token,
//         "Content-Type": "application/json",
//       },
//     });
//     let resp = yield AxiosInstace.post(
//       "/api/v1/whatsapp/send_document_message",
//       JSON.stringify(action.pay)
//     );
//     yield put({
//       type: "UPLOAD_SUC",
//       mid: action.mid,
//       cid: action.cid,
//       id: resp.data.data.id,
//     });
//   } catch (e) {}
// }
// function* watchSendFile() {
//   yield takeEvery("SEND_FILE_SAGA", sendFile);
// }
function* SendForward(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let res;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    res = yield AxiosInstace.post("/api/v1/whatsapp/forward_message", JSON.stringify({ whatsapp_chat_message_id: action.msId.toString(), whatsapp_chat_id: action.chatId, id: "4" }));
    yield put({
      type: "UPLOAD_SUC",
      mid: action.mid,
      cid: action.cid,
      id: res.data.data.id,
    });
  } catch (e) {

  }
}
function* watchForward() {
  yield takeEvery("SEND_TEXT_SAGA_FORWARD", SendForward)
}
async function getContactInfo(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let res;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    res = await AxiosInstace.get("api/v1/whatsapp_contacts/show/" + action.payload);
    put({ type: "", payload: res })
  }
  catch (e) {

  }
}
function* changeName(action) {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  let res;
  const base_user =
    store?.getState().whatsapp.fetched_url === true
      ? store?.getState()?.whatsapp.base_user
      : process.env.REACT_APP_USER_BASE_URL;
  let AxiosInstace = axios.create({
    baseURL: `${base_user}`,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  try {
    if(action.id)
    yield AxiosInstace.put("api/v1/whatsapp_contacts/" + action.id, JSON.stringify({ name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid }))
    else{
    let res=yield AxiosInstace.post("api/v1/whatsapp_contacts", JSON.stringify({number:action.payload.number, name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid }))
      yield put({type:"ADD_CONTACT",payload:res.data.data})
    }
  }
  catch (e) { }
}
function* watchChangeName() {
  yield takeEvery("CHANGE_NAME", changeName)
}
function* getMessages(action) {
  try {
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    document.body.style.cursor='wait';
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.post("api/v1/whatsapp/get_all_messages_between_two_messages", JSON.stringify({
      'first_message_id': action.sid?.toString(),
      'second_message_id': action.qid?.toString(),
      'whatsapp_chat_id': action.cid?.toString()
    }))
    yield put({ type: "GET_MES_RED", payload: res.data.data, cid: action.cid, id: action.sid })
    document.body.style.cursor='default';
  }
  catch (e) {

  }
}
function* WatchGetMessages() {
  yield takeEvery("GET_MES", getMessages)
}
function* getChatData(action) {
  try {
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.get("/api/v1/whatsapp_contact_roles")
    yield put({ type: "GET_ROLS", payload: res.data.data })
    let resd = yield AxiosInstace.get("/api/v1/whatsapp_contact_companies")
    yield put({ type: "GET_COMPA", payload: resd.data.data })
    let resp = yield AxiosInstace.get("api/v1/whatsapp_contacts")
    yield put({ type: "GET_CONTACT", payload: resp.data.data })
  }
  catch (e) { }
}
function* watchGetData() {
  yield takeEvery("GET_DATA_CHA", getChatData)
}
function* readChat(action) {
  try {
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.get("/api/v1/whatsapp/read_chat_messages?whatsapp_chat_id=" + action.id)

  }
  catch (e) { }
}
function* watchReadChat() {
  yield takeEvery("READ_CHAT", readChat)
}
function* getChat(action) {
  try {
    let id = action.id
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.get(`/api/v1/whatsapp/get_one_chat_with_latest_messages/${id}?limit=600`)
    yield put({
      type: "GET_PAGE", payload: {
        messages: res.data.data.messages,
        id: id
      }
    });
  }
  catch (e) { }
}
function* watchGetOneChat() {
  yield takeEvery("GET_WA_ONE_CHAT", getChat)
}
function* searchWord(action) {
  if (action.payload.length>0) {
    try {
      const token = store.getState()?.whatsapp?.userTokenWhatsapp;
      const base_user =
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_user
          : process.env.REACT_APP_USER_BASE_URL;
          yield put({type:"SEARCH_PROGRESS",payload:true})
      let AxiosInstace = axios.create({
        baseURL: `${base_user}`,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });

      let res;
      if (!store.getState().whatsapp.wa_loading) {
        res = yield AxiosInstace.get(
          `/api/v1/whatsapp/get_chats_with_latest_messages?search=${action.payload}`,
        );

        if (!store.getState().whatsapp.wa_loading)
          yield put({
            type: "SEARCH_WA", payload: {
              
              data: res.data.data
            }
          });
          yield put({type:"SEARCH_PROGRESS",payload:false})

      }
    } catch (e) {
      store.dispatch({ type: OFFLINE });

    }
  }
  else {
    yield put({type:"SEARCH_WA",payload:{search:"",data:[]}})
  }

}
function* watchSearchWord() {
  yield takeEvery("SEARCH_WA_SAGA", searchWord)
}
export default function* WhatsappSaga() {
  yield all([
    fork(watchGetChat),
    fork(watchSendText),
    fork(watchGetGroubs),
    fork(watchGetPage),
    fork(watchGetGroupInfo),
    // fork(watchSendFile),
    fork(watchForward),
    fork(watchChangeName),
    fork(WatchGetMessages),
    fork(watchGetData),
    fork(watchReadChat),
    fork(watchGetOneChat),
    fork(watchSearchWord)
  ]);
}
