import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import ability from './config/acl/ability'
import { AbilityContext } from './config/acl/Can'
// Import custom components
import store from './redux/store';
import * as serviceWorker from './serviceWorker';

import { getLanguagesFiles } from './redux/translate/actions'

getLanguagesFiles()
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AbilityContext.Provider value={ability}>
                <App />
            </AbilityContext.Provider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
serviceWorker.register()