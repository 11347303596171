import React,{useState,useEffect, useRef} from "react"
import { useSelector } from "react-redux"
import "./contact.css"
import ContactItem from "./ContactItem"
const ContactsList=(props)=>{
    const [search,setSearch]=useState("")
    
    const companies=useSelector((state)=>state.whatsapp.companies)
    const roles=useSelector((state)=>state.whatsapp.roles)
    const [filterItems,setFilterItems]=useState([])
    const Filter=(a)=>{
       
        if(filterItems.includes(a)){
            setFilterItems(filterItems.filter((s)=>s!==a))
        }
        else{
            setFilterItems([...filterItems,a])
        }
        if(a===0){setFilterItems([]); setFilterRoles([])}
    }
    const [filterRoles,setFilterRoles]=useState([])
    const FilterRole=(a)=>{
   
        if(filterRoles.includes(a)){
            setFilterRoles(filterRoles.filter((s)=>s!==a))
        }
        else{
            setFilterRoles([...filterRoles,a])
        }
    }
    const getData=()=>{
        let arr=[]
        if(filterItems.includes(0)) return props.data
        if(filterItems.length===0){
            arr=props.data
        }
        else{
        arr=props.data.filter((s)=>filterItems.includes(s.whatsapp_contact_company_id))
    }

arr=getRoles(arr)
return arr
    }
    const getRoles=(arr)=>{
        if(filterRoles.length===0){
            return arr
        }
        else{
            return arr.filter((s)=>filterRoles.includes(s.whatsapp_contact_role_id))
        }
    }
    return(
        <div className="contacts-container">
            <div className="contats-header">
                <div className="contact-header-title">
                <svg id="_20X20_photo_Back" data-name="20X20 photo Back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
                <g id="Mask_Group_311" data-name="Mask Group 311">
                    <g id="notebook-of-contacts">
                    <path id="Path_21348" data-name="Path 21348" d="M4.769,10A.769.769,0,0,1,4,10.769H2.462A.769.769,0,0,1,1.692,10h0a.769.769,0,0,1,.769-.769H4A.769.769,0,0,1,4.769,10Zm0-4.923A.769.769,0,0,0,4,4.308H2.462a.769.769,0,0,0-.769.769h0a.769.769,0,0,0,.769.769H4a.769.769,0,0,0,.769-.769Zm0,9.846A.769.769,0,0,0,4,14.154H2.462a.769.769,0,0,0-.769.769h0a.769.769,0,0,0,.769.769H4a.769.769,0,0,0,.769-.769ZM18.308,2.462V17.538A2.462,2.462,0,0,1,15.846,20H5.077a2.462,2.462,0,0,1-2.462-2.462V16.308H4a1.385,1.385,0,0,0,0-2.769H2.615V11.385H4A1.385,1.385,0,1,0,4,8.615H2.615V6.461H4A1.385,1.385,0,1,0,4,3.692H2.615V2.462A2.462,2.462,0,0,1,5.077,0H15.846A2.462,2.462,0,0,1,18.308,2.462ZM8.55,7.3a2.374,2.374,0,1,0,2.374-2.374A2.374,2.374,0,0,0,8.55,7.3Zm6.066,5.8a2.637,2.637,0,0,0-2.637-2.637H9.868A2.638,2.638,0,0,0,7.231,13.1v1.055h7.385Z" fill="#5d5d5d"/>
                    </g>
                </g>
                </svg>

                    <span>Contacts List</span>
                </div>
                <div onClick={()=>props.close()} className="colse-contact">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.416" height="11.414" viewBox="0 0 11.416 11.414">
                    <g id="Group_10287" data-name="Group 10287" transform="translate(-859.049 -110.051)">
                        <line id="Line_962" data-name="Line 962" x1="14.141" y1="0.001" transform="translate(859.758 110.758) rotate(45)" fill="none" stroke="#ff2e2e" strokeLinecap="round" stroke-width="1"/>
                        <line id="Line_963" data-name="Line 963" x1="14.141" y2="0.001" transform="translate(869.756 110.758) rotate(135)" fill="none" stroke="#ff2e2e" strokeLinecap="round" stroke-width="1"/>
                    </g>
                    </svg>
                </div>
            </div>
            <div style={{width:props.small?"391px":"100%"}} className="contact-filter-container">
                <div className={`contact-filter-item ${(filterItems.includes(0)||(filterItems.length===0&&filterRoles.length===0))&&"active-f"}`} onClick={()=>Filter(0)}>
                    <span className="filter-title">All Contacts</span>
                    <span className="filter-count">{props.data.length}</span>
                </div>
                {companies.map((s)=>(
                    <div className={`contact-filter-item ${filterItems.includes(s.id)&&"active-f"}`} onClick={()=>Filter(s.id)}>
                    <span className="filter-title">{s.name}</span>
                    <span className="filter-count">{props.data.filter((d)=>d.whatsapp_contact_company_id===s.id).length}</span>
                </div>
                ))}
               {roles.map((s)=>(
                    <div className={`contact-filter-item ${filterRoles.includes(s.id)&&"active-f"}`} onClick={()=>FilterRole(s.id)}>
                    <span className="filter-title">{s.name}</span>
                    <span className="filter-count">{props.data.filter((d)=>d.whatsapp_contact_role_id===s.id).length}</span>
                </div>
                ))}
            </div>
            <div className="container-search search-component">
            <svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
            <g id="Mask_Group_165" data-name="Mask Group 165">
                <g id="_x32_-Magnifying_Glass" transform="translate(-2.969 -2.971)">
                <path id="Path_19050" data-name="Path 19050" d="M17.842,17.225l-3.408-3.408a6.54,6.54,0,0,0-9.547-8.93,6.54,6.54,0,0,0,8.93,9.547l3.408,3.408a.436.436,0,0,0,.617-.617ZM5.5,13.523a5.671,5.671,0,1,1,4.01,1.661A5.635,5.635,0,0,1,5.5,13.523Z" fill="#8e8e8e"/>
                </g>
            </g>
            </svg>

            <input value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search In Table" className="" />
            </div>
            <div className="contacts-list">
              {getData().filter((s)=>((s.name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()))||(s.pushname?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())))).map((s)=>(
                    <ContactItem item={s} small={props.small} close={()=>props.close()}/>
              ))}
            </div>
        </div>
    )
}
export default ContactsList