import React, { useEffect } from "react";
import Spinner from "../../../../Spinner";
import { useDispatch, useSelector } from "react-redux";
import Message from "./Message";
import { toast } from "react-toastify";

var vCard = require('vcard-parser');
const getMessageTime = (dss) => {
  let no = new Date();
  let d = new Date(dss?.sent_at);
  if (no.getHours() === d.getHours()) {
    return d
  }
  d = d.getTime() + 3 * 60 * 60 * 1000;
  return d
};

const DatesMessages = (mes) => {
  let rr = mes

  rr.sort((a, b) =>
    parseInt(new Date(a.sent_at).getTime()) - parseInt(new Date(b.sent_at).getTime())
  )
  let arr = []
  rr.forEach((s) => {
    arr.push({ ...s, sent_at: getMessageTime(s) })
  })
  let newMes = []
  arr.forEach((a) => {
    let mesDate = new Date(a.sent_at)
    mesDate = `${mesDate.getFullYear()}-${(mesDate.getMonth() + 1) > 9 ? (mesDate.getMonth() + 1).toString() : ("0" + (mesDate.getMonth() + 1).toString())}-${(mesDate.getDate()) > 9 ? mesDate.getDate() : "0" + parseInt(mesDate.getDate()).toString()}`
    if (newMes.filter((item) => item.date === mesDate).length === 0)
      newMes.push({ date: mesDate, messages: [] })
  })
  arr.forEach((a) => {
    let mesDate = new Date(a.sent_at)
    mesDate = `${mesDate.getFullYear()}-${(mesDate.getMonth() + 1) > 9 ? (mesDate.getMonth() + 1).toString() : ("0" + (mesDate.getMonth() + 1).toString())}-${(mesDate.getDate()) > 9 ? mesDate.getDate() : "0" + parseInt(mesDate.getDate()).toString()}`
    if (newMes.filter((newM) => newM.date === mesDate)?.length > 0) {
      let obj = newMes.filter((newMs) => newMs.date === mesDate)[0]
      obj.messages = [...obj.messages, a]
      newMes.forEach((it) => {
        if (it.date === obj.date) {
          it = { ...obj }
        }
      })

    }

  })
  if (newMes.length > 0)
    return (newMes)
  else {
    let mesDate = new Date()
    mesDate = `${mesDate.getFullYear()}-${(mesDate.getMonth() + 1) > 9 ? (mesDate.getMonth() + 1).toString() : ("0" + (mesDate.getMonth() + 1).toString())}-${(mesDate.getDate()) > 9 ? mesDate.getDate() : "0" + parseInt(mesDate.getDate()).toString()}`

    return [{ date: mesDate, messages: [] }]
  }
}

const Convo = ({ img, setImg, setVid, vid, getAudioMessages, lastMsgRef, setReplyMessage, dateOfConversation }) => {
  const dispatch = useDispatch()
  const qoutedMessage = useSelector((state) => state.whatsapp.qoutedMessage)
  const activeChat = useSelector(state => state.whatsapp?.activeChat);
  const active = useSelector((state) => state.whatsapp.activeChat)
  const is_fetch = useSelector((state) => state.whatsapp.is_fetch)
  const allMessages = useSelector(state => state?.whatsapp?.activeChat?.messages) || [];
  const datetMessages = DatesMessages(allMessages)
  const GetMessage = (msgId, quoteId) => {

    if (allMessages.filter((f) => f.id === quoteId).length > 0) {
      var numb = quoteId
      let el = document.querySelector(`#s${numb}`)
      if (el) {
        el.scrollIntoView({ block: "center" })

        setTimeout(() => {
          el.classList.add("backdrop_msg")
        }, 300)
        setTimeout(() => {
          el.classList.remove("backdrop_msg")
        }, 1200)
      }
    }
    else {
      dispatch({ type: "GET_MES", cid: activeChat.id, qid: allMessages[0].id, sid: quoteId })
    }
  }
  useEffect(() => {
    setTimeout(() => {
      if (qoutedMessage) {
        var numb = qoutedMessage
        let el = document.querySelector(`#s${numb}`)
        if (el) {

          el.scrollIntoView({ block: "center" })
          setTimeout(() => {
            el.classList.add("backdrop_msg")
          }, 300)
          setTimeout(() => {
            el.classList.remove("backdrop_msg")
          }, 1200)
        }
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qoutedMessage])
  const getVcardName = (card) => {
    var myVCard = vCard.parse(card);
    return (myVCard?.fn[0].value)
  }
  const getVcardWaId = (card) => {
    var myVCard = vCard.parse(card);
    if (myVCard?.tel[0]?.meta?.waid[0]) {
      return (myVCard?.tel[0].meta.waid[0])
    }
    else {
      return null
    }
  }
  const CopyText = (a, text) => {
    if (a.type === "vcard") {

      let elem = document.querySelector("#text-copy");

      elem.value = `name: ${getVcardName(text)}\n phone: ${getVcardWaId(text)}`
      elem.select()
      document.execCommand('Copy');
      toast.success("Copied!")
    }
    else
      if (text && text.length > 0) {
        let elem = document.querySelector("#text-copy");
        elem.value = text
        elem.select()
        document.execCommand('Copy');
        toast.success("Copied!")
      }
  }
  const showMessages = (mes) => {
    return (
      <>
        {mes?.map((a, i) => {
          if (a.whatsapp_chat_id === activeChat?.id || a.cid === activeChat?.id || a.chat_id === activeChat?.id) {
            return (<Message copy={() => CopyText(a, a.body)} getAudioMessages={() => getAudioMessages()} key={i} GetMessage={(m, n) => GetMessage(m, n)} setReplyMessage={setReplyMessage} active={active} img={img} vid={vid} setVid={(e) => setVid(e)} setImg={(e) => setImg(e)} activeChat={activeChat} a={a} i={i} />)
          }
          else {
            return null
          }
        }
        )}
      </>
    )
  }

  useEffect(() => {
    setTimeout(() => {

      lastMsgRef?.current?.scrollIntoView()
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  let now = new Date();
  const showDate = (d) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let day = new Date(d)
    day = days[day.getDay()]
    if (d === nowString)
      return ("Today")
    else if ((new Date(nowString) - new Date(d)) === 86400000) {
      return ("Yesterday")
    }
    else if ((new Date(nowString) - new Date(d)) < (86400000 * 6))
      return (day)
    else
      return (d)
  }
  let nowString = `${now.getFullYear()}-${(now.getMonth() + 1) > 9 ? (now.getMonth() + 1).toString() : ("0" + (now.getMonth() + 1).toString())}-${(now.getDate()) > 9 ? now.getDate() : "0" + parseInt(now.getDate()).toString()}`

  return (
    <>
      <textarea style={{ opacity: 0, height: '0px' }} id="text-copy" />

      {(is_fetch) && <Spinner no={true} />}

      {/* <div className={`chat__date-wrapper ${DatesMessages(allMessages)[0].date===dateOfConversation.current&&"scroledk"}`}>
     { dateOfConversation.current&&     <span className="chat__date"> {showDate(dateOfConversation.current)}</span>}
          </div> */}
      {datetMessages.map((date, dateIndex) => (
        <div key={dateIndex}>
          {!active?.new ?
            // <IntersectionElement key={dateIndex} onChange={(e)=>{if(e.isIntersecting)dateOfConversation.current=date.date}}>

            <div className="dates-element" aria-details={date.date} style={{ position: "relative", zIndex: `${allMessages.length - dateIndex}` }}>
              {<div className={`chat__date-wrapper normal-date-wrapper`}>
                <span className="chat__date normal-date"> {showDate(date.date)}</span>
              </div>}
              <div className={`${"group_widg"} chat__msg-group`}>
                {showMessages(date.messages)}

              </div>
            </div>
            // </IntersectionElement>
            :
            <div className="dates-element" aria-details={date.date} style={{ position: "relative", zIndex: `${allMessages.length - dateIndex}` }}>
              {date.messages.length < 4 && <div className={`chat__date-wrapper normal-date-wrapper`}>
                <span className="chat__date normal-date"> {showDate(date.date)}</span>
              </div>}

              <div className={`${"group_widg"} chat__msg-group`}>
                {showMessages(date.messages)}

              </div>
            </div>
          }
        </div>

      ))}
    </>
  );
};

export default Convo;